



.main-container {
    max-width: 85%;
    margin: 0 auto;
}

/* Birthday Section */
#birthday-header {
    min-width: 100%;
    max-width: 85%;
    display:flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center
}

.birthday-header-child {
    margin: 0 auto;
}

/* Injury Section */
#injury-header {
    min-width: 100%;
    max-width: 85%;
    display:flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 1% 0;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.player-item {
    padding: 10px;
}

