
#main-container {
    padding-bottom: 20px;

}

.brand {
    background: #343a40;
    
}

.brand a {
    color: white;
    padding: 1%;
}

.navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    list-style: none;
    margin: 0; 
    background: #343a40;
}

.navigation a {
    text-decoration: none;
    display: block;
    padding: 1em;
    color: white;
}

.navigation a:hover {
    background: #941414;
    color: white;
}

@media all and (max-width: 800px) {
    .brand {
    text-align: center; 
    background: #343a40;
    color: white;
    }
    .brand a {
    padding-top: 3%;
    padding-bottom: 3%
    }

    .navigation {
        justify-content: space-around;
    }
}

@media all and (max-width: 600px) {
    .navigation {
    flex-flow: column wrap;
    padding: 0;
    }
    .navigation a { 
    text-align: center; 
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255,0.3); 
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
    }
    .navigation li:last-of-type a {
    border-bottom: none;
    }
}